import React, { useState, useRef, useEffect } from 'react';
import { Popover, PopoverTrigger, PopoverContent, Tooltip } from "@nextui-org/react";

export interface Legend {
  id: number;
  name: string;
  color: string;
  is_selected: boolean;
  type: string;
}

export interface LegendProps {
  legends: Legend[];
  onLegendsChange: (legends: Legend[]) => void;
  portalContainer: any;
}

export const SeriesLegend: React.FC<LegendProps> = ({ legends, onLegendsChange, portalContainer }) => {
  const [loading, setLoading] = useState<any>(false);
  const [textWidths, setTextWidths] = useState<any>([]);
  const divRef = useRef<any>();
  const hiddenDivRef = useRef<any>();

  useEffect(() => {
    const calculateTextWidths = () => {
      const widths = legends.map(legend => {
        hiddenDivRef.current.innerText = legend.name;
        return hiddenDivRef.current.clientWidth;
      });
      setTextWidths(widths);
    };

    calculateTextWidths();
  }, [legends]);

  const showLegendList = (isTruncate = false) => {

    return legends.map((legend, index) => {
      const isOverflow = textWidths[index] > (divRef.current.clientWidth - 25);
      return (
        <div
          key={legend.id}
          onClick={() => handleLegendClick(legend.id)}
          className={`cursor-pointer p-1 m-1 flex items-center ${isTruncate ? 'h-4' : ''}`}
          style={{
            opacity: legend.is_selected ? 1 : 0.5,
          }}
        >
          <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
          <span
            className='rounded-full w-1 h-1'
            style={{
              backgroundColor: legend.color,
            }}></span>
          <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>

          <Tooltip
            className="bg-[#00000090]"
            key={index}
            isDisabled={!isOverflow}
            placement="top"
            content={
              <div className="px-1 py-2 text-white max-w-lg break-all">
                {legend.name}
              </div>
            }
          >
            <div className={`text-white ${isTruncate ? 'truncate ...' : ''}`}>{legend.name}</div>
          </Tooltip>
        </div>

      )
    });
  }

  const handleLegendClick = async (clickedLegendId: number) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newLegends = legends.map((legend) => {
      if (legend.id === clickedLegendId) {
        return { ...legend, is_selected: !legend.is_selected };
      }
      return legend;
    });
    onLegendsChange(newLegends);
    // await new Promise(resolve => setTimeout(resolve, 500));
    setLoading(false);
  };

  return (
    <div className='absolute flex items-start bottom-0 p-[5px] left-0 bg-[#00000030] max-w-full'>
      <div
        ref={hiddenDivRef}
        className='p-1 m-1 absolute -left-[9999px] invisible whitespace-nowrap'
      />
      <div className="flex flex-col max-h-[65px] overflow-hidden " ref={divRef}>
        {showLegendList(true)}
      </div>
      {
        legends.length > 3 && (
          <Popover size="lg" placement="top" offset={20} showArrow={true} className="before:bg-[#00000050]" portalContainer={portalContainer}>
            <PopoverTrigger>
              <i className="cursor-pointer bi bi-chevron-double-up text-[16px] text-white"></i>
            </PopoverTrigger>
            <PopoverContent className="bg-[#00000050]">
              <div className="px-1 py-2 text-[16px] text-white max-h-80 overflow-y-auto">
                {showLegendList()}
              </div>
            </PopoverContent>
          </Popover>
        )
      }
    </div>
  );
}
