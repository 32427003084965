import {
  JulianDate,
  Math as MathCesium,
  Cartesian3,
  ClockRange,
  TimeInterval,
  TimeIntervalCollection,
  Transforms,
  HeadingPitchRoll,
  Ellipsoid,
  DebugModelMatrixPrimitive,
  Matrix4,
  Color,
  LabelStyle,
  VerticalOrigin,
  Cartesian2,
} from 'cesium';
import { SimulationType, PanelType, SerieType } from 'types';
import { DEFAULT_LAT, DEFAULT_LONG, DEFAULT_PITCH, MATLAB_DEG_VERSION, MATLAB_DEG_OLD_VERSION, compareVersions, getColor } from 'common';
import axios from 'axios';
import _ from 'lodash';

export function formatDateTime(date: any): string {
  if (!date) {
    return '';
  }
  const utcDate = JulianDate.toDate(date);
  const hour = utcDate.getHours().toString().padStart(2, '0');
  const minute = utcDate.getMinutes().toString().padStart(2, '0');
  const second = utcDate.getSeconds().toString().padStart(2, '0');
  return `${hour}:${minute}:${second}`;
}

export function toHourString(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const hoursPad = String(hours).padStart(2, '0');
  const minutesPad = String(minutes).padStart(2, '0');
  const secondsPad = String(remainingSeconds).padStart(2, '0');

  return `${hoursPad}:${minutesPad}:${secondsPad}`;
}

export const degreesToRadians = (degrees: any) => {
  return degrees * (Math.PI / 180);
}

export const getItemByKey = (key: string, compareKey: string, list: any[]) => {
  if (!list?.length) {
    return {};
  }
  return list.find(item => item?.[key]?.toLowerCase() === compareKey.toLowerCase());
}

export function generateDescription(timeVals: any, longitudeVals: any, latitudeVals: any, altitudeVals: any, event: any, isIIP = false) {
  return `
  <table class="table-auto">
    <tr>
     <td>Time</td>
     <td>${timeVals}</td>
    </tr>
    <tr>
      <td>Latitude${isIIP ? '_IIP' : ''}</td>
      <td>${latitudeVals}</td>
    </tr>
    <tr>
      <td>Longitude${isIIP ? '_IIP' : ''}</td>
      <td>${longitudeVals}</td>
    </tr>
    ${!isIIP ? `<tr>
        <td>Altitude</td>
        <td>${Number(altitudeVals).toFixed(2)}</td>
      </tr>` : ''
    }
    ${event ? `<tr>
      <td>Event</td>
      <td>${event}</td>
    </tr>` : ''
    }
  <tr>
  </table>
  `
}

export function createDispersionDesc(timeVals: any, longitudeVals: any, latitudeVals: any, distance: any) {
  return `
  <table class="table-auto" style="font-size: 14px">
    <tr>
     <td style="width: 180px; color: gray">Time (s)</td>
     <td>${timeVals}</td>
    </tr>
    <tr>
      <td style="color: gray">Latitude_IIP (deg)</td>
      <td>${latitudeVals}</td>
    </tr>
    <tr>
      <td style="color: gray">Longitude_IIP (deg)</td>
      <td>${longitudeVals}</td>
    </tr>
    <tr>
      <td style="color: gray">RSS_plus_dis_IIP (km)</td>
      <td>${distance}</td>
    </tr>
  <tr>
  </table>
  `
}

export function lockCamera(viewer: any, isLock: boolean) {
  viewer.scene.screenSpaceCameraController.enableRotate = !isLock;
  viewer.scene.screenSpaceCameraController.enableTranslate = !isLock;
  viewer.scene.screenSpaceCameraController.enableZoom = !isLock;
  viewer.scene.screenSpaceCameraController.enableTilt = !isLock;
  viewer.scene.screenSpaceCameraController.enableLook = !isLock;
}

function extractTimeComponents(timeString: any) {
  let time = parseFloat(timeString);
  let seconds = Math.floor(time);
  let milliseconds = Math.round((time - seconds) * 1000);
  return {
    seconds: seconds,
    milliseconds: milliseconds
  };
}

export function initDate(time: string) {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  if (!time) {
    return currentDate;
  }
  if (parseInt(time, 10) < 9999) {
    const { seconds, milliseconds } = extractTimeComponents(time);
    currentDate.setSeconds(seconds, milliseconds);
    return currentDate;
  }
  return new Date(time);
}

export function getTimeRange(data: Object[]) {
  const dateRange = data
    .flatMap((item: any) => {
      const timeData = getItemByKey('name', 'time', item.fields)?.values || [];
      return [timeData[0], timeData.at(-1)]
    }).map((time: string) => initDate(time))
    .sort((a: any, b: any) => a - b);
  return dateRange;
}

export function isValidLatitude(latitude: any) {
  return !isNaN(latitude) && latitude !== null && latitude >= -90 && latitude <= 90;
}

export function isValidLongitude(longitude: any) {
  return !isNaN(longitude) && longitude !== null && longitude >= -180 && longitude <= 180;
}

export function isValidCoordinates(latitude: any, longitude: any) {
  return isValidLatitude(latitude) && isValidLongitude(longitude);
}

export function movePositionByDistance(latitude: any, longitude: any, distanceMeters: any) {
  const latitudeRadians = MathCesium.toRadians(latitude);
  const longitudeRadians = MathCesium.toRadians(longitude);
  const earthRadius = 6378137.0;

  const angleDegrees = distanceMeters >= 0 ? -90 : 90;
  const angleRadians = MathCesium.toRadians(angleDegrees);
  const absDistanceMeters = Math.abs(distanceMeters);

  const eastMeters = absDistanceMeters * Math.cos(angleRadians);
  const northMeters = absDistanceMeters * Math.sin(angleRadians);

  const deltaLatitude = northMeters / earthRadius;
  const newLatitude = latitudeRadians + deltaLatitude;

  const radiusAtLatitude = earthRadius * Math.cos(latitudeRadians);
  const deltaLongitude = eastMeters / radiusAtLatitude;
  const newLongitude = longitudeRadians + deltaLongitude;

  const newLatitudeDegrees = MathCesium.toDegrees(newLatitude);
  const newLongitudeDegrees = MathCesium.toDegrees(newLongitude);

  return {
    latitude: newLatitudeDegrees,
    longitude: newLongitudeDegrees
  };
}

export function sortByNameWithNumber(array: any[], sortByField: string) {
  return array.sort((a, b) => {
    const numA = parseInt(a[sortByField].match(/\d+$/)?.[0], 10);
    const numB = parseInt(b[sortByField].match(/\d+$/)?.[0], 10);
    if (a[sortByField].replace(/\d+$/, '') === b[sortByField].replace(/\d+$/, '')) {
      return numA - numB;
    }
    return a[sortByField].localeCompare(b[sortByField], undefined, { numeric: true, sensitivity: 'base' });
  });
}

export function formatTimeWithMiliSeconds(num: any) {
  let rounded = Number(num).toFixed(1);
  if (rounded.indexOf('.0') !== -1) {
    return parseInt(rounded, 10);
  }
  return rounded;
}

export function getFieldData(serieData: any, options: any) {
  let longFieldName = 'longitude';
  let latFieldName = 'latitude';
  let pitchFieldName = 'pitch';
  let heightFieldName = 'altitude';
  if (options.panelType === PanelType.dispersion) {
    longFieldName = 'Longitude_IIP';
    latFieldName = 'Latitude_IIP';
  }
  if (options.simulationType === SimulationType.GNC) {
    pitchFieldName = 'Theta';
    heightFieldName = 'Position_X';
  }
  const fields = [
    { name: 'time', key: 'timeData' },
    { name: longFieldName, key: 'longData' },
    { name: 'iip_longitude', key: 'iipLongData' },
    { name: 'iip_latitude', key: 'iipLatData' },
    { name: latFieldName, key: 'latData' },
    { name: heightFieldName, key: 'altData' },
    { name: 'RSS_plus_dis_IIP', key: 'rssData' },
    { name: 'q_B_ECI_x', key: 'q_B_ECI_xData' },
    { name: 'q_B_ECI_y', key: 'q_B_ECI_yData' },
    { name: 'q_B_ECI_z', key: 'q_B_ECI_zData' },
    { name: 'q_B_ECI_s', key: 'q_B_ECI_sData' },
    { name: 'yaw', key: 'headingData' },
    { name: pitchFieldName, key: 'pitchData' },
    { name: 'roll', key: 'rollData' },
    { name: 'event', key: 'eventData' },
    { name: 'Position_Y', key: 'distanceData' },
    { name: 'TVC_delta', key: 'thrustAngleData' },
    { name: 'Thrust_power_1', key: 'thrustPowerData1' },
    { name: 'Thrust_power_ratio_1', key: 'thrustPowerRatioData1' },
    { name: 'Thrust_power_2', key: 'thrustPowerData2' },
    { name: 'Thrust_power_ratio_2', key: 'thrustPowerRatioData2' },
    { name: 'Thrust_power_3', key: 'thrustPowerData3' },
    { name: 'Thrust_power_ratio_3', key: 'thrustPowerRatioData3' },
    { name: 'Thrust_power_4', key: 'thrustPowerData4' },
    { name: 'Thrust_power_ratio_4', key: 'thrustPowerRatioData4' },
  ];

  const result: any = {};

  fields.forEach(({ name, key }) => {
    result[key] = getItemByKey('name', name, serieData.fields)?.values || [];
  });
  if (options.simulationType === SimulationType.GNC) {
    result.longData = Array(result.timeData.length).fill(DEFAULT_LONG);
    result.latData = Array(result.timeData.length).fill(DEFAULT_LAT);
    result.headingData = Array(result.timeData.length).fill(0);
    result.rollData = Array(result.timeData.length).fill(0);
  }

  return result;
}

export const setCameraFly = async (viewer: any, lat: any, long: any, height: any, options: any, finishCallback: any = () => { }, renderAxis: any) => {
  let cameraHeight = 10000;
  switch (options?.panelType) {
    case PanelType.dispersion:
      cameraHeight = 5000000;
      break;
    case PanelType.static:
      cameraHeight = 150;
      break;
    case PanelType.thrust:
      cameraHeight = 10;
      break;
    default:
      cameraHeight = 10000;
      break;
  }
  const isStaticPanel = options?.panelType === PanelType.static;
  const destination = Cartesian3.fromDegrees(long, lat, cameraHeight);
  const heading = MathCesium.toRadians(90.0);
  const pitch = MathCesium.toRadians(-45.0);
  const firstEntity = viewer.entities.add({
    position: Cartesian3.fromDegrees(long, lat, height),
    point: { pixelSize: 0 }
  });

  lockCamera(viewer, true);
  viewer?.camera?.flyTo({
    destination: destination,
    orientation: {
      heading: heading,
      pitch: pitch,
      roll: 0,
    },
    duration: 2,
    complete: async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!viewer.scene) {
        finishCallback();
        return;
      }
      viewer.trackedEntity = firstEntity;
      await new Promise(resolve => setTimeout(resolve, 500));
      viewer.camera.zoomOut(cameraHeight);
      await new Promise(resolve => setTimeout(resolve, 500));
      if (isStaticPanel) {
        viewer.camera.rotateLeft(MathCesium.toRadians(135.0));
        renderAxis();
      } else {
        viewer.trackedEntity = null;
      }
      await new Promise(resolve => setTimeout(resolve, 500));
      finishCallback();
    }
  });
};

export const setTimelineViewer = (
  viewer: any,
  series: any,
  setSatelliteAvailability: any,
  setTimestamp: any,
  setShouldAnimate: any
) => {
  let allTimes = getTimeRange(series);
  if (!viewer) {
    return;
  }

  const { timeline, clock } = viewer;
  const startTimestamp: Date | null = allTimes[0] ?? null;
  const endTimestamp: Date | null = allTimes.at(-1) ?? null;

  if (startTimestamp !== null) {
    setTimestamp(JulianDate.fromDate(startTimestamp));
  } else {
    setTimestamp(null);
  }

  if (startTimestamp && endTimestamp) {
    setSatelliteAvailability(
      new TimeIntervalCollection([
        new TimeInterval({
          start: JulianDate.fromDate(startTimestamp),
          stop: JulianDate.fromDate(endTimestamp),
        }),
      ])
    );
  } else {
    setSatelliteAvailability(null);
  }

  if (viewer && clock && timeline && startTimestamp && endTimestamp) {
    const start = JulianDate.fromDate(startTimestamp);
    const stop = JulianDate.fromDate(endTimestamp);
    clock.startTime = start.clone();
    clock.stopTime = stop.clone();
    clock.currentTime = start.clone();
    clock.clockRange = ClockRange.LOOP_STOP;
    timeline.zoomTo(start, stop);
    setShouldAnimate(false);
  }
};

export const initPitch = (inputPitch: any, options: any) => {
  if (options?.simulationType === SimulationType.GNC) {
    return (DEFAULT_PITCH + MathCesium.toRadians(inputPitch));
  }
  return inputPitch;
}

const addAxisToViewer = (viewer: any, position: any, text: string, color: any) => {
  viewer.entities.add({
    position: position,
    label: {
      text: text,
      font: '12pt monospace',
      style: LabelStyle.FILL,
      fillColor: color,
      outlineWidth: 10,
      verticalOrigin: VerticalOrigin.BOTTOM,
      pixelOffset: new Cartesian2(0, -9)
    }
  });
}

export const drawAxis = (long: any, lat: any, height: any, viewer: any) => {
  const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('north', 'west');
  const length = 50;
  const position = Cartesian3.fromDegrees(long, lat, height);
  const modelMatrix = Transforms.headingPitchRollToFixedFrame(
    position,
    new HeadingPitchRoll(),
    Ellipsoid.WGS84,
    fixedFrameTransform,
  );
  viewer.scene.primitives.add(
    new DebugModelMatrixPrimitive({
      modelMatrix: modelMatrix,
      length: length,
      width: 1.0,
    }),
  );
  const xEnd = new Cartesian3(length, 0.0, 0.0);
  const yEnd = new Cartesian3(0.0, length, 0.0);
  const zEnd = new Cartesian3(0.0, 0.0, length);

  const xEndWorld = Matrix4.multiplyByPoint(modelMatrix, xEnd, new Cartesian3());
  const yEndWorld = Matrix4.multiplyByPoint(modelMatrix, yEnd, new Cartesian3());
  const zEndWorld = Matrix4.multiplyByPoint(modelMatrix, zEnd, new Cartesian3());
  addAxisToViewer(viewer, xEndWorld, 'X', Color.RED);
  addAxisToViewer(viewer, yEndWorld, 'Y', Color.GREEN);
  addAxisToViewer(viewer, zEndWorld, 'Z', Color.BLUE);
}

export const addCircleToViewer = (id: string, viewer: any, options: any, position: any, radius: any, description: any, color: any = Color.RED.withAlpha(0.3)) => {
  if (options.panelType !== PanelType.dispersion || radius <= 0) {
    return;
  }
  const finalRadius = options?.dispersionDistanceUnit === 'm' ? parseFloat(radius) : (radius * 1000);
  viewer.entities.removeById(id);
  viewer.entities.add({
    position: position,
    id: id,
    ellipse: {
      semiMinorAxis: finalRadius,
      semiMajorAxis: finalRadius,
      material: color,
      height: 100,
    },
    zIndex: radius,
    description
  });
}

export const getIdFromString = (inputString: string) => {
  const regex = /Simulation_(\d+)_/;
  const match = inputString.match(regex);
  return match?.[1] || null;
}

export const fetchSimulationData = async (simulationID: any) => {
  try {
    const API_URL = process.env.GF_PLUGIN_REACT_APP_API_END_POINT;
    const API_KEY = process.env.GF_PLUGIN_REACT_APP_GRAFANA_REQUEST_SECRET_KEY;
    const response = await axios.get(`${API_URL}/v1/grafana/simulators/${simulationID}`, {
      headers: {
        'secret_key': API_KEY
      }
    });
    return response.data;
  } catch (err) {
    return null
  }
};

export const is3DofMain = (options: any) => {
  return (options?.simulationType === undefined || options?.simulationType?.toLowerCase() === SimulationType.ThreeDoF.toLowerCase()) && options?.panelType !== PanelType.dispersion;
}

export const isDegGitVersion = (version: any) => {
  if (!version || typeof version !== 'string') {
    return false;
  }
  if (version.startsWith('v') || version.startsWith('V')) {
    return compareVersions(version, MATLAB_DEG_VERSION);
  }
  return version >= MATLAB_DEG_OLD_VERSION;
}

const setColorForSeries = (series: any) => {
  for (const [index, value] of series.entries()) {
    value.color = getColor(index);
  }
  return series;
}

export const addIIPLineSeries = (series: any[], options: any) => {
  const { simulationType, panelType } = options;
  series = sortByNameWithNumber(series, 'refId');
  if ((simulationType !== undefined &&
    simulationType?.toLowerCase() !== SimulationType.ThreeDoF.toLowerCase() &&
    simulationType?.toLowerCase() !== SimulationType.SixDoF.toLowerCase()) ||
    panelType === PanelType.dispersion ||
    panelType === PanelType.static
  ) {
    return setColorForSeries(series);
  }
  const newSeries: any = [];
  for (let i = 0; i < series.length; i++) {
    let iipSerie: any = {};
    let haveIIPData = false;
    iipSerie.type = SerieType.iip;
    iipSerie.refId = `${series[i].refId}_IIP`;
    iipSerie.fields = _.cloneDeep(series[i].fields);
    iipSerie.fields.forEach((field: any) => {
      if (field.name === 'iip_latitude' || field.name === 'iip_longitude') {
        haveIIPData = true;
      }
      if (field.name === 'altitude') {
        field.values.fill(10);
      }
    })
    newSeries.push(series[i]);
    if (haveIIPData) {
      newSeries.push(iipSerie);
    }
  }

  return setColorForSeries(newSeries);
}
