import React, { useEffect, useState, useRef } from 'react';
import { PanelProps, DataHoverEvent, LegacyGraphHoverEvent } from '@grafana/data';
import { AssetMode, SimpleOptions, SimulationType, PanelType, SerieType } from 'types';
import { toHourString, generateDescription, lockCamera, initDate, isValidCoordinates, movePositionByDistance, createDispersionDesc, getFieldData, setCameraFly, setTimelineViewer, initPitch, drawAxis, addCircleToViewer, getIdFromString, fetchSimulationData, is3DofMain, isDegGitVersion, addIIPLineSeries } from 'utilities';
import { DEFAULT_LAT, DEFAULT_LONG, DEFAULT_HEIGHT } from 'common';
import { Viewer, Clock, Entity, ModelGraphics, PathGraphics } from 'resium';
import {
  Ion,
  JulianDate,
  TimeInterval,
  TimeIntervalCollection,
  Cartesian3,
  Quaternion,
  Transforms,
  SampledProperty,
  SampledPositionProperty,
  Color,
  PolylineDashMaterialProperty,
  IonResource,
  VelocityOrientationProperty,
  HeadingPitchRoll,
  Ellipsoid,
  Math as MathCesium
} from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
import '../css/output.css';
import { TopControl } from './TopControl';
import { Legend, SeriesLegend } from './SeriesLegend';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { ThrustIndicator } from './ThrustIndicator';
import { IIPLine } from './IIPLine';
import { SeriePoint } from './SeriePoint';

interface Props extends PanelProps<SimpleOptions> { }

export const SatelliteVisualizer: React.FC<Props> = ({ options, data, timeRange, width, height, eventBus }) => {
  Ion.defaultAccessToken = options.accessToken;
  const viewerRef = useRef<any>(null);
  const modelRef = useRef<any>(null);
  const wrapRef = useRef<any>(null);

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isFinishLoad, setIsFinishLoad] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<JulianDate | null>(null);
  const [satelliteAvailability, setSatelliteAvailability] = useState<TimeIntervalCollection | null>(null);
  const [satellitePositions, setSatellitePositions] = useState<SampledPositionProperty[] | null>(null);
  const [satelliteOrientations, setSatelliteOrientations] = useState<SampledProperty[] | null>(null);
  const [satelliteResources, setSatelliteResources] = useState<IonResource[] | string[] | any[]>([]);
  const [clockMultiplier, setClockMultiplier] = useState<number>(1);
  const [legends, setLegends] = useState<Legend[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const [finalData, setFinalData] = useState<any[]>([]);

  const isStaticPanel = () => options?.panelType === PanelType.static;
  const isThrustPanel = () => options?.simulationType === SimulationType.GNC && options?.panelType === PanelType.thrust;

  const handleSpeedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseFloat(event.target.value);
    dispatchAction('speedChange', { speed: value });
  };

  const playReverse = () => {
    const currentSpeed = clockMultiplier === 0 ? -1 : clockMultiplier;
    const speed = currentSpeed > 0 ? currentSpeed * -1 : currentSpeed;
    dispatchAction('playReverse', { speed, back: true, play: true });
  };

  const playForward = () => {
    const currentSpeed = clockMultiplier === 0 ? 1 : clockMultiplier;
    const speed = currentSpeed < 0 ? currentSpeed * -1 : currentSpeed;
    dispatchAction('playForward', { speed, back: false, play: true });
  };

  const pause = () => {
    dispatchAction('pause', {});
  };

  const initLegends = (series: any) => {
    let newLegends: any = [];
    for (const [index, serie] of series.entries()) {
      newLegends = [...newLegends, { id: index, name: serie.refId || '', color: serie?.color, is_selected: true, type: serie.type }];
    }
    setLegends(newLegends);
  };

  const setResources = (dataFrame: any, index: number) => {
    if (dataFrame?.type === SerieType.iip) {
      return;
    }
    const modelAssetId = dataFrame.fields[12]?.values[0];
    if (modelAssetId) {
      IonResource.fromAssetId(modelAssetId, { accessToken: options.accessToken })
        .then((resource) => {
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: resource,
          }));
        })
        .catch((error) => {
          console.error('Error loading Ion Resource of Model:', error);
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: null,
          }));
        });
      return;
    }

    if (options.modelAssetId) {
      IonResource.fromAssetId(options.modelAssetId, { accessToken: options.accessToken })
        .then((resource) => {
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: resource,
          }));
        })
        .catch((error) => {
          console.error('Error loading Ion Resource of Model:', error);
          setSatelliteResources(oldVal => ({
            ...oldVal,
            [index]: null,
          }));
        });
      return;
    }
    if (options.modelAssetUri) {
      setSatelliteResources(oldVal => ({
        ...oldVal,
        [index]: options.modelAssetUri,
      }));
      return;
    }

    setSatelliteResources(oldVal => ({
      ...oldVal,
      [index]: null,
    }));
  };

  useEffect(() => {
    const currentDate = new Date();
    const timeInterval = new TimeInterval({
      start: JulianDate.addDays(JulianDate.fromDate(currentDate), -7, new JulianDate()),
      stop: JulianDate.fromDate(currentDate),
    });
    // https://community.cesium.com/t/correct-way-to-wait-for-transform-to-be-ready/24800
    Transforms.preloadIcrfFixed(timeInterval).then(() => setLoaded(true));
  }, [timeRange]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (finalData.length) {
      const calculateData = async () => {
        let convertDegToRadEnable = false;
        if (is3DofMain(options)) {
          const simulationId = getIdFromString(document.title);
          const simulationData = await fetchSimulationData(simulationId);
          convertDegToRadEnable = isDegGitVersion(simulationData?.data?.version_name);
        }

        const positionProperties = [];
        const orientationProperties = [];
        const initSeries = [];
        const viewer = viewerRef?.current?.cesiumElement;
        setTimelineViewer(viewer, finalData, setSatelliteAvailability, setTimestamp, setShouldAnimate);
        initLegends(finalData);
        for (const [serieIndex, serie] of finalData.entries()) {
          setResources(serie, serieIndex);
          const positionProperty = new SampledPositionProperty();
          const orientationProperty = new SampledProperty(Quaternion);
          const { timeData, longData, latData, altData, q_B_ECI_xData, q_B_ECI_yData, q_B_ECI_zData, q_B_ECI_sData, headingData, pitchData, rollData, eventData, distanceData, iipLongData, iipLatData } = getFieldData(serie, options);
          const serieData = [];

          for (const [index, value] of timeData.entries()) {
            const time = JulianDate.fromDate(initDate(value));
            const dataIndex = (options.panelType === PanelType.static) ? 0 : index;
            let description: any;
            let pixelSize = options.trajectoryPositionSize;
            let latVal = latData[dataIndex];
            let longVal = longData[dataIndex];

            if (serie.type === SerieType.iip) {
              latVal = iipLatData[index];
              longVal = iipLongData[index];
              eventData[index] = null;
            }

            if (options?.simulationType === SimulationType.GNC) {
              const finalCoordinate = movePositionByDistance(latVal, longVal, distanceData[index]);
              latVal = finalCoordinate.latitude;
              longVal = finalCoordinate.longitude;
            }
            if (!isValidCoordinates(latVal, longVal)) {
              continue;
            }
            const x_ECEF = Cartesian3.fromDegrees(
              Number(longVal),
              Number(latVal),
              Number(altData?.[dataIndex] || 0)
            );
            let q_B_ECI = new Quaternion();
            if (headingData?.length && pitchData?.length && rollData?.length) {
              const heading = convertDegToRadEnable ? MathCesium.toRadians(Number(headingData?.[index])) : Number(headingData?.[index]);
              const pitch = convertDegToRadEnable ? MathCesium.toRadians(Number(pitchData?.[index])) : Number(pitchData?.[index]);
              const roll = convertDegToRadEnable ? MathCesium.toRadians(Number(rollData?.[index])) : Number(rollData?.[index]);
              const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('north', 'west');
              const ellipsoid = Ellipsoid.WGS84;
              q_B_ECI = Transforms.headingPitchRollQuaternion(x_ECEF, new HeadingPitchRoll(heading, initPitch(pitch, options), roll), ellipsoid, fixedFrameTransform);
            }
            if (options.simulationType?.toLowerCase() === SimulationType.SixDoF?.toLowerCase()) {
              q_B_ECI = new Quaternion(
                Number(q_B_ECI_xData[index]),
                Number(q_B_ECI_yData[index]),
                Number(q_B_ECI_zData[index]),
                Number(q_B_ECI_sData[index])
              );
            }
            positionProperty.addSample(time, x_ECEF);
            orientationProperty.addSample(time, q_B_ECI);
            const timeToSeconds = JulianDate.secondsDifference(
              time,
              JulianDate.fromDate(new Date(timeData[0]))
            );
            const timeToText = toHourString(timeToSeconds);

            if (is3DofMain(options)) {
              if (eventData[index]) {
                pixelSize = options.trajectoryPositionSize * 2;
              }
              description = generateDescription(
                timeToText,
                longData[index],
                latData[index],
                altData[index],
                eventData[index]
              );
            }
            if (serie.type === SerieType.iip) {
              description = generateDescription(
                timeToText,
                longVal,
                latVal,
                null,
                null,
                true
              );
            }
            serieData.push({ time: timeData[index], timeToSeconds, position: x_ECEF, color: serie?.color, pixelSize, event: eventData[index], description });
          }

          positionProperties.push(positionProperty);
          orientationProperties.push(orientationProperty);
          initSeries.push(serieData);
        }
        setSeries(initSeries);
        setSatellitePositions(positionProperties);
        setSatelliteOrientations(orientationProperties);
      }
      calculateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalData, isLoaded]);

  const checkViewerAvailable: any = async () => {
    const viewer = viewerRef?.current?.cesiumElement;
    if (viewer && data?.series?.length) {
      if (isStaticPanel()) {
        viewer.scene.globe.show = false;
        viewer.scene.skyBox.show = false;
        viewer.scene.skyAtmosphere.show = false;
        viewer.scene.sun.show = false;
      }
      const { timeData, longData, latData, altData, rssData } = getFieldData(data.series[0], options);
      const firstLat = Number(latData[0]) || DEFAULT_LAT;
      const firstLong = Number(longData[0]) || DEFAULT_LONG;
      const firstHeight = Number(altData[0]) || DEFAULT_HEIGHT;

      const renderAxis = () => {
        if (isStaticPanel()) {
          drawAxis(firstLong, firstLat, firstHeight, viewer);
        }
      }

      for (const [index, value] of timeData.entries()) {
        if (!isValidCoordinates(latData[index], longData[index])) {
          continue;
        }
        const position = Cartesian3.fromDegrees(
          Number(longData[index]),
          Number(latData[index]),
          0
        );
        const description = createDispersionDesc(
          value,
          longData[index],
          latData[index],
          rssData[index]
        );
        addCircleToViewer(`circle_${index}`, viewer, options, position, rssData[index], description);
      }

      setCameraFly(viewer, firstLat, firstLong, firstHeight, options, () => {
        setIsFinishLoad(true);
        lockCamera(viewer, false);
      }, renderAxis);
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
      await checkViewerAvailable();
    }
  };

  useEffect(() => {
    checkViewerAvailable();

    const handleListenerAction = () => {
      let currentAction = JSON.parse(localStorage.getItem('currentAction') || '{}');
      handleSyncAction(currentAction);
    };

    const params = new URLSearchParams(window.location.search);
    const editPanel = params.get('editPanel');
    if (editPanel === '1') {
      setIsEditMode(true);
    }

    document.addEventListener('dispatchAction', handleListenerAction);
    return () => {
      document.removeEventListener('dispatchAction', handleListenerAction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Ion.defaultAccessToken = options.accessToken;
  }, [options.accessToken]);

  useEffect(() => {
    if (!options.subscribeToDataHoverEvent) {
      return;
    }

    const dataHoverSubscriber = eventBus.getStream(DataHoverEvent).subscribe((event) => {
      if (event?.payload?.point?.time) {
        setTimestamp(JulianDate.fromDate(new Date(event.payload.point.time)));
      }
    });

    const graphHoverSubscriber = eventBus.getStream(LegacyGraphHoverEvent).subscribe((event) => {
      if (event?.payload?.point?.time) {
        setTimestamp(JulianDate.fromDate(new Date(event.payload.point.time)));
      }
    });

    return () => {
      dataHoverSubscriber.unsubscribe();
      graphHoverSubscriber.unsubscribe();
    };
  }, [eventBus, options.subscribeToDataHoverEvent]);

  useEffect(() => {
    if (viewerRef?.current?.cesiumElement) {
      const viewer = viewerRef.current.cesiumElement;
      viewer.clock.multiplier = isBack ? clockMultiplier * -1 : clockMultiplier;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockMultiplier]);

  useEffect(() => {
    if (data?.series?.length) {
      const newData = addIIPLineSeries(data.series, options);
      setFinalData(newData);
    }
  }, [data, options.accessToken, options.modelAssetId, options.modelAssetUri, options]);

  const handleSyncAction = (currentAction: any) => {
    const viewer = viewerRef.current?.cesiumElement;
    if (currentAction.playForward) {
      const { speed, back, play } = currentAction.playForward;
      viewer.clockViewModel.multiplier = speed;
      setClockMultiplier(speed);
      setIsBack(back);
      viewer.clockViewModel.shouldAnimate = play;
      setShouldAnimate(play);
    }
    if (currentAction.pause) {
      viewer.clockViewModel.shouldAnimate = !viewer.clockViewModel.shouldAnimate;
      setShouldAnimate(viewer.clockViewModel.shouldAnimate);
    }
    if (currentAction.playReverse) {
      const { speed, back, play } = currentAction.playReverse;
      viewer.clockViewModel.multiplier = speed;
      setIsBack(back);
      viewer.clockViewModel.shouldAnimate = play;
      setShouldAnimate(play);
    }
    if (currentAction.speedChange) {
      const { speed } = currentAction.speedChange
      setClockMultiplier(speed);
    }
    if (currentAction.timelineChange) {
      const { currentTime } = currentAction.timelineChange;
      setTimestamp(currentTime);
    }
    if (currentAction.legendChange) {
      const { legends } = currentAction.legendChange;
      setLegends(legends);
    }
  }

  const dispatchAction = (name: string, value: any) => {
    const currentAction = { [name]: value };
    if (isEditMode) {
      handleSyncAction(currentAction);
      return;
    }
    localStorage.setItem('currentAction', JSON.stringify(currentAction));
    const myEvent = new Event('dispatchAction');
    document.dispatchEvent(myEvent);
  };

  return (
    <div className="relative">
      <div
        className="relative font-sans"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
        ref={wrapRef}
      >
        <Viewer
          full
          animation={false}
          timeline={true}
          infoBox={options.showInfoBox}
          baseLayerPicker={options.showBaseLayerPicker}
          sceneModePicker={options.showSceneModePicker}
          projectionPicker={options.showProjectionPicker}
          navigationHelpButton={false}
          fullscreenButton={false}
          geocoder={false}
          homeButton={false}
          creditContainer="cesium-credits"
          ref={viewerRef}
          className={isStaticPanel() ? 'panel-sm' : ''}
        >
          {timestamp && <Clock currentTime={timestamp} />}
          {satelliteAvailability &&
            satellitePositions &&
            satelliteOrientations &&
            satellitePositions.map(
              (satellitePosition, index) =>
                legends.length > 0 &&
                legends[index]?.type !== SerieType.iip &&
                legends[index]?.is_selected && (
                  <Entity
                    availability={satelliteAvailability}
                    position={satellitePosition}
                    orientation={
                      options.autoComputeOrientation
                        ? new VelocityOrientationProperty(satellitePosition)
                        : satelliteOrientations[index]
                    }
                    tracked={false}
                    ref={modelRef}
                    key={index}
                    name={data.series[index]?.refId}
                  >
                    {options.assetMode === AssetMode.model && satelliteResources[index] && (
                      <ModelGraphics
                        uri={satelliteResources[index]}
                        scale={options.modelScale}
                        minimumPixelSize={options.modelMinimumPixelSize}
                        maximumScale={options.modelMaximumScale}
                        key={index}
                      />
                    )}

                    {options.trajectoryShow && (
                      <PathGraphics
                        width={options.trajectoryWidth}
                        material={
                          new PolylineDashMaterialProperty({
                            color: Color.fromCssColorString(options.trajectoryColor),
                            dashLength: options.trajectoryDashLength,
                          })
                        }
                      />
                    )}
                  </Entity>
                )
            )}

          {(options.trajectoryPositionShow && !isStaticPanel()) && (
            <>
              <SeriePoint
                viewerRef={viewerRef}
                series={series}
                legends={legends}
                options={options}
                data={finalData}
              />
              <IIPLine
                viewerRef={viewerRef}
                series={series}
                legends={legends}
                options={options}
                data={finalData}
              />
            </>
          )}
        </Viewer>
        <div id="cesium-credits" className={options.showCredits ? 'block' : 'hidden'}></div>
      </div>
      {
        (!isStaticPanel() && !isThrustPanel()) && (
          <>
            <SeriesLegend
              legends={legends}
              onLegendsChange={(newLegends: Legend[]) => dispatchAction('legendChange', { legends: newLegends })}
              portalContainer={wrapRef.current}
            />
            {
              options.panelType !== PanelType.dispersion ? (
                <TopControl
                  viewerRef={viewerRef}
                  data={finalData}
                  options={options}
                  isPlaying={shouldAnimate}
                  isBack={isBack}
                  playReverse={playReverse}
                  pause={pause}
                  playForward={playForward}
                  handleSpeedChange={handleSpeedChange}
                  currentSpeed={clockMultiplier}
                  legends={legends}
                  isStaticPanel={isStaticPanel()}
                  dispatchAction={dispatchAction}
                  isFinishLoad={isFinishLoad}
                />
              ) : null
            }
          </>
        )
      }
      {
        isThrustPanel() && (
          <ThrustIndicator
            viewerRef={viewerRef}
            data={finalData}
            isPlaying={shouldAnimate}
            options={options}
            legends={legends}
          />
        )
      }
    </div>
  );
};
