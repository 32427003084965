import React, { useEffect, useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import { SimulationType } from 'types';
import { formatTimeWithMiliSeconds, getFieldData } from 'utilities';

export const AltitudesControl: React.FC<any> = ({ data, options, legends, portalContainer, isStaticPanel, currentSeconds, startPlayTimestamp }) => {
  const [altitudeByTime, setAltitudeByTime] = useState<any>({});
  const [currentAltitude, setCurrentAltitude] = useState<any>({});

  const showAltitudeList = (isTruncate = false) => {
    return legends.map((item: any, index: number) => (
      item.is_selected && (
        <div key={index} className="flex cursor-pointer items-center">
          <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
          <span className='rounded-full w-1 h-1'
            style={{ backgroundColor: item.color }}
          ></span>
          <span className='bg-[#ccc] w-1 h-[1px] m-[2px]'></span>
          <span>: {currentAltitude?.[item.name] || 0}</span>
        </div>
      )
    ))
  }

  useEffect(() => {
    if (data.length >= 1) {
      const dataByTime: any = {};
      const currentAltitudeByRefId: any = {};
      for (const serie of data) {
        dataByTime[serie.refId] = {};
        currentAltitudeByRefId[serie.refId] = 0;
        const { timeData, altData } = getFieldData(serie, options);

        for (const [index, value] of timeData.entries()) {
          if (index !== 0 && timeData[index] === timeData[index - 1]) {
            const newVal = Number(value + (0.1 * (index % 10))).toFixed(1);
            dataByTime[serie.refId][newVal] = altData[index];
          } else {
            dataByTime[serie.refId][value] = altData[index];
          }
        };
      };
      setAltitudeByTime(dataByTime);
      setCurrentAltitude(currentAltitudeByRefId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options?.modelAssetId, options?.modelAssetUri]);

  useEffect(() => {
    const timeInSeconds = parseInt(currentSeconds, 10);
    const currentTime = options.simulationType === SimulationType.GNC ? formatTimeWithMiliSeconds(currentSeconds) : startPlayTimestamp + (timeInSeconds * 1000);
    for (const serie of data) {
      const altitudeDataItem = altitudeByTime[serie.refId]?.[currentTime];
      if (altitudeDataItem) {
        setCurrentAltitude((oldVal: any) => ({ ...oldVal, [serie.refId]: Number(altitudeDataItem).toFixed(2) }));
      }
    }
  }, [currentSeconds, altitudeByTime, data, options.simulationType, startPlayTimestamp]);

  return (
    <div className={`${isStaticPanel ? 'text-sm' : 'text-base min-w-60'} text-white font-sans flex gap-x-2 justify-end items-center `}>
      Altitudes:
      {
        legends.filter((item: any) => item.is_selected)?.length === 1 && (
          <div className="flex flex-col max-h-[21px] overflow-hidden">
            {showAltitudeList(true)}
          </div>
        )
      }
      {
        legends.filter((item: any) => item.is_selected)?.length > 1 && (
          <Popover size="lg" placement="bottom-end" offset={50} showArrow={true} className="before:bg-[#00000050]" portalContainer={portalContainer}>
            <PopoverTrigger>
              <i className="cursor-pointer bi bi-chevron-double-down text-[16px] text-white"></i>
            </PopoverTrigger>
            <PopoverContent className="bg-[#00000050]">
              <div className="px-1 py-2 text-[16px] text-white">
                {showAltitudeList()}
              </div>
            </PopoverContent>
          </Popover>
        )
      }
    </div>
  );
}
