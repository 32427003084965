import React, { useEffect, useState } from 'react';
import {
  JulianDate,
  Color,
} from 'cesium';
import { getTimeRange } from 'utilities';
import { SerieType } from 'types';
import { Entity } from 'resium';


export const IIPLine: React.FC<any> = ({
  viewerRef,
  series,
  legends,
  options,
  data,
}) => {
  const [timeBySeconds, setTimeBySeconds] = useState<any>(0);

  const renderTimelineAndEvents = (clock: any) => {
    const allTimes = getTimeRange(data);
    const startTimestamp: Date = allTimes[0] ?? new Date();
    const startTime = JulianDate.fromDate(startTimestamp);

    const updateProgress = (clock: any) => {
      let timeBySecond = JulianDate.secondsDifference(clock.currentTime, startTime);
      timeBySecond = timeBySecond < 0 ? 0 : timeBySecond;
      setTimeBySeconds(parseInt(String(timeBySecond), 10));
    }

    clock.onTick.removeEventListener(updateProgress);
    clock.onTick.addEventListener(updateProgress);
  };

  const initPoint = (isIIP: boolean, index: number, item: any) => {
    if (!isIIP) {
      return {
        pixelSize: 0,
      };
    }
    if (timeBySeconds > 0 && item.timeToSeconds === timeBySeconds) {
      return {
        pixelSize: item.pixelSize * 2,
        color: Color.fromCssColorString(legends[index].color),
        outlineColor: Color.fromCssColorString('#FFFFFF'),
        outlineWidth: 3
      };
    }
    return {
      pixelSize: item.pixelSize * 1.2,
      color: Color.fromCssColorString(legends[index].color),
    }
  }

  useEffect(() => {
    const viewer = viewerRef?.current?.cesiumElement;
    if (viewer?.clock) {
      renderTimelineAndEvents(viewer.clock)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legends, options]);

  return (
    series.map((serie_item: any, index: number) => {
      if (!legends.length || !legends[index]?.is_selected) {
        return null;
      }
      const isIIP = legends[index]?.type === SerieType.iip;
      return (
        <>
          <Entity
            key={index}
            name={data[index]?.refId}
            polyline={{
              positions: serie_item.map((item: any) => item.position),
              width: isIIP ? 1 : 0,
              material: Color.fromCssColorString(legends[index].color),
            }}
          />
          {serie_item.map((item: any) => (
            <Entity
              position={item.position}
              point={initPoint(isIIP, index, item)}
              key={index}
              name={data[index]?.refId}
              description={item.description}
            />
          ))}
        </>
      );
    })
  );
};
