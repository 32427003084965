import React, { useEffect, useState } from 'react';
import {
  JulianDate
} from 'cesium';
import GaugeChart from './GaugeChart';
import { getFieldData, getTimeRange, formatTimeWithMiliSeconds } from 'utilities';

export const ThrustIndicator: React.FC<any> = ({ 
  viewerRef,
  data,
  isPlaying,
  options,
  legends
}) => {
  const [dataByTime, setDataByTime] = useState<any>({});
  const [currentSeconds, setCurrentSeconds] = useState<any>(0);

  useEffect(() => {
    if (data.length >= 1) {
      const initData: any = {};
      const viewer = viewerRef?.current?.cesiumElement;
      for (const serie of data) {
        const { timeData, thrustAngleData, thrustPowerData1, thrustPowerRatioData1, thrustPowerData2, thrustPowerRatioData2, thrustPowerData3, thrustPowerRatioData3, thrustPowerData4, thrustPowerRatioData4 } = getFieldData(serie, options);
        for (const [index, value] of timeData.entries()) {
          const angle = Number(thrustAngleData[index]).toFixed(2);
          const thrustData = {
            angle: parseFloat(angle),
            power1: thrustPowerData1[index],
            ratio1: thrustPowerRatioData1[index],
            power2: thrustPowerData2[index],
            ratio2: thrustPowerRatioData2[index],
            power3: thrustPowerData3[index],
            ratio3: thrustPowerRatioData3[index],
            power4: thrustPowerData4[index],
            ratio4: thrustPowerRatioData4[index],
          }
          if (index !== 0 && timeData[index] === timeData[index - 1]) {
            const newVal = Number(value + (0.1 * (index % 10))).toFixed(1);
            initData[newVal] = thrustData;
          } else {
            initData[value] = thrustData;
          }
        };
      };
      setDataByTime(initData);
      const allTimes = getTimeRange(data);
      const startTimestamp: Date = allTimes[0] ?? new Date();
      const startTime = JulianDate.fromDate(startTimestamp);

      const updateProgress = (clock: any) => {
        let timeBySecond = JulianDate.secondsDifference(clock.currentTime, startTime);
        timeBySecond = timeBySecond < 0 ? 0 : timeBySecond;
        setCurrentSeconds(formatTimeWithMiliSeconds(timeBySecond));
      }
      if (viewer?.clock) {
        viewer.clock.onTick.removeEventListener(updateProgress);
        viewer.clock.onTick.addEventListener(updateProgress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options?.modelAssetId, options?.modelAssetUri, legends]);

  return (
    <div className='absolute bottom-0 right-0 bg-[#00000070] pt-3'>
      <div className="flex flex-col gap-2 mx-2 justify-center items-center content-center">
        <div className="flex gap-2">        
          {
            [1,2,3,4].map((index) => {
              const power = Number(dataByTime?.[currentSeconds]?.[`power${index}`]);
              const thrustPower: any = power === 0 ? 0 : power.toFixed(2);
              const thrustRatio: any = Number((dataByTime?.[currentSeconds]?.[`ratio${index}`] || 0)*100).toFixed(2);
              const styles = getStyles(thrustRatio);
              const isOn = thrustPower > 0;
              return (
                <div className="flex flex-col gap-2 justify-center items-center content-center" key={index}>
                  <div className='min-w-20 text-sm bg-gray-300 py-2 rounded text-black text-center'>{thrustPower} N</div>
                  <div className='min-w-20 text-sm bg-gray-300 py-2 rounded text-black text-center'>{thrustRatio}%</div>
                  <div className='flex h-[150px] w-4 border border-white items-end'><div className='bg-red-500' style={styles.fillColumn}></div></div>
                  <div className="flex gap-1">
                    <div className={`transition w-3 h-3 rounded-full border ${(isOn && currentSeconds > 0) ? 'bg-red-600' : 'bg-white'}`} />
                    <div className={`transition w-3 h-3 rounded-full border ${(!isOn && currentSeconds > 0) ? 'bg-green-600' : 'bg-white'}`} />
                  </div>
                </div>
              )
            })
          }
        </div>
        <GaugeChart data={dataByTime?.[currentSeconds]?.angle}/>
      </div>
      
    </div>
  );
};
const getStyles: any = (rate: number) => {
  return {
    fillColumn: {
      height: `${rate}%`,
      width: '100%',
      transition: `height 0.1s ease-in-out`,
    }
  };
};
