import React, { useEffect, useState } from 'react';
import {
  toHourString,
} from 'utilities';

export const ClockControl: React.FC<any> = ({ isStaticPanel, currentSeconds }) => {
  const [currentTime, setCurrentTime] = useState<string>(toHourString(0));

  useEffect(() => {
    setCurrentTime(toHourString(currentSeconds));
  }, [currentSeconds]);

  return (
    <div className={`flex items-center justify-center text-white py-[10px] font-sans mx-[20px] grow`}>
      {currentTime !== '' && <div className={`${isStaticPanel ? 'text-xl' : 'text-5xl'}`}>{currentTime}</div>}
    </div>
  );
}
