import React, { useState } from 'react';
import {
  Color,
  Cartesian2
} from 'cesium';
import { SerieType } from 'types';
import { Entity } from 'resium';


export const SeriePoint: React.FC<any> = ({
  viewerRef,
  series,
  legends,
  options,
  data,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<{ serie: number; position: number } | null>(null);

  const handleMouseMove = (serie: number, position: number) => {
    setHoveredIndex({ serie, position });
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    series.map((serie_item: any, index: number) => {
      if (!legends.length || !legends[index]?.is_selected || legends[index]?.type === SerieType.iip) {
        return null;
      }

      return serie_item.map((item: any, itemIndex: number) => (
        <Entity
          position={item.position}
          point={{
            pixelSize: item.pixelSize,
            color: Color.fromCssColorString(item.color),
          }}
          key={index}
          label={
            item.event && hoveredIndex?.serie === index && hoveredIndex?.position === itemIndex
              ? {
                text: item.event,
                font: '12px sans-serif',
                fillColor: Color.WHITE,
                outlineWidth: 1,
                outlineColor: Color.BLACK,
                pixelOffset: new Cartesian2(0, -20),
              }
              : undefined
          }
          name={data[index]?.refId}
          description={item.description}
          onMouseMove={() => handleMouseMove(index, itemIndex)}
          onMouseLeave={handleMouseLeave}
        />
      ));
    })
  );
};
