import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);
const GaugeChart: React.FC<any> = ({ data }) => {
  const options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: 'transparent',
      spacing: [0, 20, 0, 20],
      width: 200,
      height: 160
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    pane: {
      startAngle: 105,
      endAngle: 255,
      background: null,
      center: ['50%', '30%'],
    },
    yAxis: {
      min: -75,
      max: 75,
      reversed: true,
      tickPixelInterval: 30,
      tickPosition: 'inside',
      tickColor: 'white',
      tickLength: 15,
      tickWidth: 2,
      minorTickInterval: 3,
      labels: {
        distance: 20,
        style: {
          fontSize: '11px',
          color: '#FFFFFF',
        },
      },
      lineWidth: 0,
      plotBands: [
        {
          color: 'transparent',
          thickness: 10,
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: 'Thrust Angle',
        data: [data],
        tooltip: {
          valueSuffix: ' deg',
        },
        dataLabels: {
          format: '{y} deg',
          borderWidth: 0,
          color: '#FFFFFF',
          verticalAlign: 'bottom',
          padding: 30,
          style: {
            fontSize: '12px',
          },
        },
        dial: {
          radius: '80%',
          backgroundColor: 'white',
          baseWidth: 8,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: 'white',
          radius: 4,
        },
      },
    ],
  };
  return (
    <div className="w-auto">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default GaugeChart;
