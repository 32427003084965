export const DEFAULT_LAT = 28.60838888888887;
export const DEFAULT_LONG = -80.60433333333319;
export const DEFAULT_HEIGHT = 10000;
export const DEFAULT_PITCH = -1.5708;

export const getColor = (index: number) => {
  return [
    '#FF0000',
    '#0000FF',
    '#800080',
    '#008000',
    '#FFA500',
    '#A52A2A',
    '#000000',
    '#008080',
    '#800000',
    '#4B0082',
    '#808000',
    '#40E0D0',
    '#C0C0C0',
    '#00A86B',
    '#DC143C',
    '#007FFF',
    '#800020',
    '#FFBF00',
    '#FFD700',
    '#0F52BA',
    '#FF2400',
    '#DE3163',
    '#B7410E',
    '#92000A',
    '#4682B4',
    '#E30B5D',
    '#50C878',
    '#B87333',
    '#D2691E',
    '#787B73',
    '#87A96B',
    '#B2BEB5',
    '#153F78',
    '#57483C',
    '#B22222',
    '#3FFF00',
    '#5A4FCF',
    '#D8BFD8',
    '#A0522D',
    '#C2B280',
    '#4169E1',
    '#E0115F',
    '#51434F',
    '#CC7732',
    '#ABA956',
    '#996515',
    '#DF73FF',
    '#556B2F',
    '#CD5C5C',
    '#ED9121',
    '#C41E3A',
    '#CB4154',
    '#848482',
    '#CD9575',
    '#0A1172',
    '#C71585',
    '#FF7F50',
    '#66FF66',
    '#20B2AA',
    '#FA8072',
    '#D2B48C',
    '#2E8B57',
    '#FFD700',
    '#ADFF2F',
    '#FF4500',
    '#2F4F4F',
    '#9ACD32',
    '#F4A460',
    '#F08080',
    '#9B870C',
    '#ADD8E6',
    '#DB7093',
    '#FF6347',
    '#FFDAB9',
    '#DDA0DD',
    '#B0C4DE',
    '#90EE90',
    '#FF00FF',
    '#333A56',
    '#8B4513',
    '#708090',
    '#EE82EE',
    '#FF1493',
    '#FFD700',
    '#B22222',
    '#00FA9A',
    '#7FFF00',
    '#FF69B4',
    '#BC8F8F',
    '#DCDCDC',
    '#8A2BE2',
    '#CD853F',
    '#EE204D',
    '#F5FFFA',
    '#00CED1',
    '#FFB6C1',
    '#AD9E8E'
  ][index];
};

export const MATLAB_DEG_VERSION = 'v1.2.0';
export const MATLAB_DEG_OLD_VERSION = 'dev/20250101_01';

export const compareVersions = (versionA: any, versionB: any) => {
  versionA = versionA.startsWith('v') ? versionA.substring(1) : versionA;
  versionB = versionB.startsWith('v') ? versionB.substring(1) : versionB;

  const partsA = versionA.split('.').map(Number);
  const partsB = versionB.split('.').map(Number);

  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const numA = partsA[i] || 0;
    const numB = partsB[i] || 0;
    if (numA > numB) {
      return true;
    }
    if (numA < numB) {
      return false;
    }
  }
  return true;
}
